import React from 'react';
import './index.scss';

class TypedText extends React.Component {



    render() {
        return (
            <>
                <span className="typed-text">Full-Stack Software Engineer</span>
            </>
        );
    }
}

export default TypedText;
